<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
      <h3 class="buyerHeaderTit"><span>{{$t('i18nn_851c121d787993a2')}}</span>
        <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
          <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
        </el-tooltip>
      </h3>
    </div> -->

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_851c121d787993a2')}}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为 我的费用明细
          </p>
        </div>
    </div> -->
		<!--  <div class="buyerDataHeader">
      <el-button type="primary" plain size="small" @click="openDioalog(null)">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
    </div> -->
		<div class="filterCon" style="">
			<div class="filterBarList">

			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList"> -->

				<!-- </ul> -->
				<ul class="filterConList">
					<li>
						<span>{{$t('i18nn_f77f473d27f48bdc')}}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span>{{$t('i18nn_d42355e6aef72227')}}</span>
						<el-date-picker v-model="filterData.daterange" size="small" type="daterange" align="right"
							:clearable="false" unlink-panels range-separator="-" start-placeholder="start date"
							end-placeholder="end date" :picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
							@change="serPageData()"></el-date-picker>
					</li>
					<li>
						<span>SKU</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku"
							maxlength="50" :clearable="true" style="width: 250px;"></el-input>

					</li>
					<li>
						<span>{{$t('i18nn_158ecb8cbcabc069')}}</span>
						<el-select filterable clearable v-model="filterData.chargeBizType" size="small"
							:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 100px;" @change="initData()">
							<el-option v-for="item in selectOption.wh_charge_biz_type" :key="item.code"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option>
						</el-select>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
				</ul>
			</div>
		</div>

		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{$t('i18nn_f77f473d27f48bdc')}}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
      <div>
        <span>{{$t('i18nn_d42355e6aef72227')}}</span>
        <el-date-picker
          v-model="filterData.daterange"
          size="small"
          type="daterange"
          align="right"
          :clearable="false"
          unlink-panels
          range-separator="-"
          start-placeholder="start date"
          end-placeholder="end date"
          :picker-options="pickerOptions"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
           @change="serPageData()"
        ></el-date-picker>
      </div>
      <div class="wh-filter-item">
        <span>SKU</span>
        <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku" maxlength="50" :clearable="true" style="width: 250px;"></el-input>
      </div>

      <el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
    </div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<!-- <div class="filterCon">
        <el-row>
          <el-col :span="10" class="sellerHeaderMsg" >
            &nbsp;
          </el-col>
          <el-col :span="6" class="sellerHeaderMsg" >
            &nbsp;
          </el-col>
          <el-col :span="6">
            <span>{{$t('i18nn_a4eca6ff2588d791')}}</span>
            <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.billNo" maxlength="50" :clearable="true" style="width: 150px;">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="serPageData" size="small">{{$t('FormMsg.Search')}}</el-button>
          </el-col>
        </el-row>
      </div> -->
			<el-table :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>

				<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.SKU_title')">
					<template slot-scope="scope">
						<div v-if="scope.row.skuDto">
							<!-- <div>
                <span>{{ scope.row.skuDto.goodsName }}</span>
              </div>
              <div v-if="scope.row.skuDto.goodsName!=scope.row.skuDto.goodsNameEn">
                <span>{{ scope.row.skuDto.goodsNameEn }}</span>
              </div> -->
							<el-popover placement="top" trigger="hover">
								<div class="pre-text">{{ scope.row.skuDto.goodsName }}</div>
								<span class="over_h120" slot="reference">{{scope.row.skuDto.goodsName}}</span>
							</el-popover>
						</div>
					</template>
				</el-table-column>


				<el-table-column prop="packingNo" :label="$t('i18nn_b7a10fe4e9528362')"></el-table-column>

				<el-table-column prop="reportDate" :label="$t('i18nn_61cac7cb94d81cfe')"></el-table-column>

				<el-table-column prop="inWhDay" :label="$t('i18nn_9eaa906ebc446062')"></el-table-column>

				<el-table-column prop="whDay" :label="$t('i18nn_4d3765c415bb4462')"></el-table-column>

				<el-table-column prop="stock" :label="$t('i18nn_de53ab10322cd38a')"></el-table-column>
				<el-table-column prop="totalVol" :label="$t('i18nn_5ada761d1917f8bc')"></el-table-column>
				<el-table-column prop="totalWeight" :label="$t('i18nn_ea71165e067be24e')"></el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_5acbec83efb27445')"></el-table-column>

				<el-table-column prop="place" :label="$t('i18nn_3a6eb04ef8f3327b')"></el-table-column>

				<el-table-column prop="vol" :label="$t('i18nn_0e240c5802d17fea')"></el-table-column>
				<el-table-column prop="weight" :label="$t('i18nn_11e41b2ec3d872ed')"></el-table-column>

				<!-- <el-table-column prop="feeTypeName" :label="$t('Storage.whFee.Fee_Type')"></el-table-column>
        <el-table-column prop="feeAmt" :label="$t('Storage.whFee.Fee')"></el-table-column>
        <el-table-column prop="feeDescription" :label="$t('Storage.whFee.Fee_description')">
          <template slot-scope="scope">
            <div v-if="scope.row.feeDescriptionJson">
              <div>
                <span>{{ $t('Storage.whFee.quantity') }}：</span>
                <span>{{ scope.row.feeDescriptionJson.putWhCount }}</span>
              </div>
              <div>
                <span>{{ $t('Storage.whFee.toll_collection_manner') }}：</span>
                <span>{{ scope.row.feeDescriptionJson.whFeeTypeName }}</span>
              </div>
              <div v-if="scope.row.feeDescriptionJson.remark">
                <span>{{ $t('Storage.tableColumn.remark') }}</span>
                <span>{{ scope.row.feeDescriptionJson.remark }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="feeDate" :label="$t('Storage.whFee.billing_date')"></el-table-column>
        <el-table-column prop="goodsSku" label="SKU">
          <template slot-scope="scope">
            <div v-if="scope.row.feeDescriptionJson">
              <div>
                <span>{{ scope.row.feeDescriptionJson.goodsSku }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" :label="$t('Storage.skuInfo.SKU_title')">
          <template slot-scope="scope">
            <div v-if="scope.row.feeDescriptionJson">
              <div>
                <span>{{ scope.row.feeDescriptionJson.goodsName }}</span>
              </div>
              <div>
                <span>{{ scope.row.feeDescriptionJson.goodsNameEn }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
          <template slot-scope="scope">
            <div v-if="scope.row.feeDescriptionJson">
              <div>
                <div>
                  <el-image
                    lazy
                    style="width: auto; height: 40px"
                    fit="contain"
                    :src="scope.row.feeDescriptionJson.goodsImg"
                    @click="openBigImg($event, scope.row.feeDescriptionJson.goodsImg)"
                  >
                    <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
                  </el-image>
                </div>
              </div>
            </div>
          </template>
        </el-table-column> -->

			</el-table>
			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false"  :title="$t('i18nn_62d1f13b854eb1f7')" append-to-body :visible.sync="dialogFormVisible">
      <el-form ref="form" :rules="formRules" :model="form" label-width="100px" v-loading="loading" :element-loading-text="$t('tips.Submitting')">

        <el-form-item label="用户账号：" prop="mobile">
          <el-input v-if="0==dialogFormStatus" v-model="form.mobile" :placeholder="$t('i18nn_29799f539ee6b906')" maxlength="11"></el-input>
          <el-input v-else v-model="form.mobile" maxlength="11" readonly></el-input>
        </el-form-item>

        <el-form-item label="用户姓名：" prop="subUserName">
          <el-input v-model="form.subUserName" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
        </el-form-item>

        <el-form-item label="所属厂家：">
          <el-input v-model="UserInfo.enterpriseName" readonly></el-input>
        </el-form-item>

        <el-form-item label="是否有效：" prop="state">
          <el-switch
            v-model="form.state"
            :active-text="$t('i18nn_02ccd2cf723f9272')"
            :inactive-text="$t('i18nn_e76d885ae1c74d4f')">
          </el-switch>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
        <el-button type="primary" @click="submitForm('form')">{{$t('FormMsg.Save')}}</el-button>
      </div>
    </el-dialog> -->
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhReportSkuStockPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		components: {
			SelAgentUser,
			whExcelCustom,
			whNoSelect
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				// activeName: 'second',
				// isShowFrom:false,
				pickerOptions: this.$PickerDate.pickerOptions_2(),
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				loading: false,
				// form: {
				//   "mobile":"",//子账户账号（手机号）
				//   "subUserId": "", //  子账户id
				//   "userSubUserId": "", //主账户子账户关系表id
				//   "subUserName": "", // 子账户名称
				//   "state": true, //子账户状态，0-有效，1-无效
				// },

				// formRules: {
				//   mobile: [
				//     { required: true, message: this.$t('i18nn_29799f539ee6b906'), trigger: 'blur' },
				//     { pattern: this.$Validate.REGEX_PHONE_International, message: this.$t('i18nn_f5a9e0830d476523')}
				//   ],
				//   subUserId: [
				//     { required: true, message: this.$t('i18nn_4b7dc78116a958d3'), trigger: 'blur' },
				//   ],
				//   userSubUserId: [
				//     { required: true, message: '请输入主账户子账户关系表ID', trigger: 'blur' },
				//   ],
				//   subUserName: [
				//     { required: true, message: this.$t('i18nn_a47da648bd79c2fc'), trigger: 'blur' },
				//   ],
				//   state: [
				//     { required: true, message: this.$t('i18nn_3821259f4f037ccf'), trigger: 'blur' },
				//   ],

				// },
				selectOption: {
					// wh_no: []
					wh_charge_biz_type: []
				},
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					agentUser: "",
					// "accountPeriod":"",
					daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils
						.fomatterDate_YYYYMMdd(new Date())
					],
					goodsSku: '',
					whNo: '',
					chargeBizType: ''
					// startTime: '', //"2021-05-05",
					// endTime: '' //"2021-06-05"
					// "billNo":""
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_charge_biz_type'],
				(data) => {
					this.selectOption.wh_charge_biz_type = data['wh_charge_biz_type'];
				});
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			//导出 excel
			exportExcel() {

				let columns = [{
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: this.$t('Storage.skuInfo.SKU_title'),
						key: 'goodsName',
						types: 'custom',
						formatter: function(row) {
							if (row.skuDto) {
								return row.skuDto.goodsName;
							} else {
								return '';
							}
						}
					},
					//   {
					//   	title: this.$t('Storage.skuInfo.SKU_title'),
					//   	key: 'goodsNameEn'
					//   },
					{
						title: this.$t('i18nn_b7a10fe4e9528362'),
						key: 'packingNo'
					},
					{
						title: this.$t('i18nn_61cac7cb94d81cfe'),
						key: 'reportDate'
					},
					{
						title: this.$t('i18nn_9eaa906ebc446062'),
						key: 'inWhDay'
					},
					{
						title: this.$t('i18nn_4d3765c415bb4462'),
						key: 'whDay'
					},
					{
						title: this.$t('i18nn_de53ab10322cd38a'),
						key: 'stock'
					},
					{
						title: this.$t('i18nn_5ada761d1917f8bc'),
						key: 'totalVol'
					},
					{
						title: this.$t('i18nn_ea71165e067be24e'),
						key: 'totalWeight'
					},
					{
						title: this.$t('i18nn_5acbec83efb27445'),
						key: 'whNo'
					},
					{
						title: this.$t('i18nn_3a6eb04ef8f3327b'),
						key: 'place'
					},

					{
						title: this.$t('i18nn_0e240c5802d17fea'),
						key: 'vol'
					},
					{
						title: this.$t('i18nn_11e41b2ec3d872ed'),
						key: 'weight'
					},
					// {
					// 	title: this.$t('i18nn_1d5fb99b98e26365'),
					// 	key: 'barcodeimg',
					// 	types: 'image'
					// },
				];
				let Data = this.tableData;

				// let data_dom = JQ('#ex_table').find(".el-table__body");
				// let obj_key_img = {};
				// // console.log(data_dom.find("tr"));
				// data_dom.find("tr").each((index, domEle)=>{
				// 	// console.log("index",index);
				// 	let imgObj = JQ(domEle).find("td .cell img");
				// 	// console.log("imgObj",imgObj);
				// 	if(imgObj){
				// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
				// 	}
				// })
				// // console.log("obj_key_img",obj_key_img);
				// Data.forEach(item=>{
				// 	item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
				// })
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'wh_InventoryStatistics';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();
				// excelUtilsNew.exportExcel(columns, Data, 'wh_InventoryStatistics',{height:20});
			},
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm) {
			//   // console.log(formParm);
			//   // this.dialogFormVisible = true;
			//   // let form = Object.assign({}, formParm);
			//   // console.log('form',form);
			//   // // 重置
			//   // this.resetForm('form');
			//   // if (null === formParm) { //新增

			//   //   this.dialogFormStatus = 0;
			//   //   form.subUserId = null;
			//   //   form.userSubUserId = null;
			//   //   form.state = true;
			//   //   //浅拷贝、对象属性的合并
			//   //   this.form = form;

			//   // } else { //修改
			//   //   this.dialogFormStatus = 1;
			//   //   form.state = form.state === '0' ? true : false;

			//   //   //浅拷贝、对象属性的合并
			//   //   this.form = form;

			//   // }
			//   this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {

			//   this.$refs[formName].validate((valid) => {

			//     if (valid) {
			//       let formData = Object.assign({}, this.form);
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {

			//         formData.id = null;

			//         this.postData(this.$urlConfig.subUserManaAdd, formData);
			//       } else {

			//         this.postData(this.$urlConfig.subUserManaUpdate, formData);
			//       }

			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   if (this.$refs[formName]) {
			//     this.$refs[formName].resetFields();

			//   } else {
			//     console.log('this.$refs[formName]',this.$refs[formName]);
			//   }
			// },

			//提交信息
			// postData(url, formData) {
			//   let _this = this;
			//   this.loading = true;

			//   formData.state = formData.state ? '0' : '1';
			//   this.$http.post(url, formData)
			//     .then(({ data }) => {
			//       console.log(this.$t('tips.submitSuccess'));
			//       console.log(data);
			//       
			//       this.loading = false;
			//       if (200 == data.code) {
			//         this.dialogFormVisible = false;
			//         this.getPageData();
			//         this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			//           type: 'success',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       } else {
			//         // if (!data.msg) {
			//         //   data.msg = this.$t('tips.submitError');
			//         // }
			//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t("tips.submitError"));
			//       _this.loading = false;
			//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },
			//查询数据
			serPageData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},
			//分页的筛选项数据
			pageFilterData() {
				console.log("daterange", this.filterData.daterange);
				let startTime = "";
				let endTime = "";
				if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
					startTime = this.filterData.daterange[0];
					endTime = this.filterData.daterange[1];
				} else {
					this.$message.warning(this.$t('tips.Please_Sel_date'));
				}
				return {
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// "partnerAccount": this.$store.getters.getUserInfo.partnerId,
					// "cusNo": this.filterData.cusNo ? this.filterData.cusNo : null,
					startTime: startTime ? startTime : null,
					endTime: endTime ? endTime : null,
					goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					clacType: this.filterData.chargeBizType ? this.filterData.chargeBizType : null,
					// "accountPeriod":this.filterData.accountPeriod,//账期
					// "billNo":this.filterData.billNo,//账单编号
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhReportSkuStockPageList, filterData)
					.then(({
						data
					}) => {
						console.log(this.$store.getters.getUserInfo);
						console.log('账单分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('账单分页，请求失败');
						this.loading_load = false;
					});
			},
			//查询数据字典
			// getDicData() {
			//   // let _this = this;
			//   // console.log(keyword);

			//   // this.loading_load = true;
			//   this.$http
			//     .put(this.$urlConfig.HyDicQueryList, ['wh_charge_biz_type'])
			//     .then(({ data }) => {
			//       console.log('查询数据字典，请求成功');
			//       console.log(data);
			//       if (200 == data.code && data.data) {
			//         // this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			//         // this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			//         // this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];

			//   //       this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			//   //       this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 				// this.selectOption.wh_container_size = data.data['wh_container_size'];
			// 				this.selectOption.wh_charge_biz_type = data.data['wh_charge_biz_type'];
			//       } else {
			//         if (!data.msg) {
			//           data.msg = this.$t("tips.submitError");
			//         }
			//         this.$message.warning(data.msg);
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('查询数据字典接口，请求失败');
			//       this.$message.error(this.$t("tips.submitRequestError"));
			//     });
			// }
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>